/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const CorpusFund = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Donation for Corpus Fund";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Corpus Fund" allowOverseas = false/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Donation_for_Corpus_Fund", event),
    []
  );

  return (
    <>
      <DonationLayout seoData={{ title: "Donation - Corpus Fund - Donations" }}>
        <div
          sx={{
            padding: "40px 0px",
            backgroundColor: "#dff2f6",
            border: "1px solid #d3d5d2",
            marginBottom: "30px",
          }}
        >
          <div className="container">
            <h1
              className="text-center"
              sx={{ fontSize: "1.7em ", fontFamily: "gotham-book" }}
            >
              Heartfulness Donations
            </h1>
          </div>
        </div>
        <Grid
          columns={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            padding: "0px 74px",
          }}
        >
          <Box>
            <Box>
              <h1
                sx={{
                  fontSize: "1.5em",
                  color: "#2d3550",
                  fontWeight: "700",
                  fontFamily: "gotham-book",
                }}
              >
                Donation for SMSF India – Corpus Fund – Diamond Jubilee – 75
                years
              </h1>
            </Box>
            <Box>
              <h4
                sx={{
                  fontFamily: "gotham-book",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  marginBottom: "1rem",
                }}
              >
                <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
                Spirituality Foundation, India.
              </h4>
              <h4
                sx={{
                  fontFamily: "gotham-book",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  marginBottom: "1rem",
                }}
              >
                Sahaj Marg Spirituality Foundation supports all the events and
                programs undertaken by <b>heartfulness.</b>
              </h4>
              <h4
                sx={{
                  fontFamily: "gotham-book",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  marginBottom: "1rem",
                }}
              >
                On completion of the donation, a confirmation email will be sent
                to you with a completed donation form attached.
              </h4>
            </Box>
            <Box>
              <h3
                sx={{
                  fontFamily: "gotham-book",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  marginBottom: "1rem",
                }}
              >
                <b> There are no refunds for the donations.</b>
              </h3>
              <h4
                sx={{
                  fontFamily: "gotham-book",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  marginBottom: "1rem",
                }}
              >
                Donation to Corpus Fund would form a part of the capital of the
                Organization.
              </h4>
            </Box>
          </Box>

          <Box>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(1, "Contribute Now")}
            />
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default CorpusFund;
